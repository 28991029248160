
<template>
  <div class="tw-grid tw-grid-cols-6 tw-gap-6">
    <div
      v-if="updatedPassword"
      class="tw-col-span-6 tw-px-4 tw-py-3 tw-my-3 tw-text-teal-900 tw-bg-teal-100 tw-border-t-4 tw-border-teal-500 tw-rounded-b tw-shadow-md sm:tw-col-span-6"
      role="alert"
    >
      <div class="tw-flex tw-items-center">
        <div class="tw-py-1">
          <svg
            class="tw-w-6 tw-h-6 tw-mr-4 tw-text-teal-500 tw-fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>
        </div>
        <div>
          <p>Successfully updated your password</p>
        </div>
      </div>
    </div>
    <div class="tw-col-span-6 sm:tw-col-span-6">
      <label
        for="password"
        class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
        >Current Password</label
      >
      <input
        type="password"
        v-model="old_password"
        id="password"
        autocomplete="password"
        class="tw-mt-1 tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-orange-500 focus:tw-border-orange-500 sm:tw-text-sm"
      />
      <p
        class="tw-mt-2 tw-text-sm tw-text-red-600"
        v-if="oldError"
        style="margin-bottom: 0"
      >
        {{ oldError }}
      </p>
    </div>

    <div class="tw-relative tw-col-span-6 sm:tw-col-span-6">
      <label
        for="new_password"
        class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
        >New Password</label
      >
      <input
        type="password"
        v-model="new_password"
        id="new_password"
        class="tw-mt-1 tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-orange-500 focus:tw-border-orange-500 sm:tw-text-sm"
      />
      <Password
        v-model="new_password"
        :strength-meter-only="true"
        class="ml-0"
      />
      <p
        class="tw-mt-2 tw-text-sm tw-text-red-600"
        v-if="newError"
        style="margin-bottom: 0"
      >
        {{ newError }}
      </p>
    </div>

    <div class="tw-col-span-6 sm:tw-col-span-6">
      <label
        for="confirm_password"
        class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
        >Confirm Password</label
      >
      <input
        type="password"
        v-model="confirm_password"
        id="confirm_password"
        class="tw-mt-1 tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-orange-500 focus:tw-border-orange-500 sm:tw-text-sm"
      />
      <p
        class="tw-mt-2 tw-text-sm tw-text-red-600"
        v-if="confirmError"
        style="margin-bottom: 0"
      >
        {{ confirmError }}
      </p>
    </div>
    <div class="tw-col-span-6 sm:tw-col-span-6">
      <button
        type="button"
        @click="setPassword"
        :disabled="isSubmitting"
        class="tw-mr-2 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-orange-500 hover:tw-bg-orange-600 focus:tw-outline-none"
      >
        <span class="tw-text-white"> {{ isSubmitting ? "Please wait ..." : "Change password"}} </span>
      </button>
    </div>
  </div>
</template>

<script>
const Password = () => import("vue-password-strength-meter");

export default {
  components: {
    Password,
  },
  data() {
    return {
      old_password: "",
      new_password: "",
      confirm_password: "",
      oldError: "",
      newError: "",
      confirmError: "",
      isSubmitting: false,
      updatedPassword: false,
    };
  },
  watch: {
    new_password(newValue, oldValue) {
      this.validatePasswordChange();
      this.validatePasswordMatch();
    },
    confirm_password(newValue, oldValue) {
      this.validatePasswordMatch();
    },
    old_password(newValue, oldValue) {
      this.oldError = "";
      this.validatePasswordChange();
    },
  },
  methods: {
    validatePasswordChange() {
      // New password must be different from old password
      if (this.old_password == this.new_password && this.new_password != "") {
        this.newError = "New password must be different from old password.";
      } else {
        this.newError = "";
      }
    },
    validatePasswordMatch() {
      // Passwords must match when not empty
      if (
        this.new_password != this.confirm_password &&
        (this.new_password != "" ||
        this.confirm_password != "")
      ) {
        this.confirmError = "Passwords must match.";
      } else {
        this.confirmError = "";
      }
    },
    async setPassword() {
      // require old and new password fields to be filled
      if (!this.old_password || !this.new_password) {
        this.newError = this.new_password ? "" : "This field is required";
        this.oldError = this.old_password ? "" : "This field is required";
        return;
      }

      if (this.isSubmitting) return;

      const data = {
        new_password: this.new_password,
        current_password: this.old_password,
      };

      this.isSubmitting = true;

      this.$store
        .dispatch("user/setPassword", data)
        .then((response) => {
          // clear all fields on success
          this.old_password = "";
          this.new_password = "";
          this.confirm_password = "";
          this.updatedPassword = true;

          // show sucess message for 3 seconds
          setTimeout(() => {
            this.updatedPassword = false;
          }, 3000);
        })
        .catch((error) => {
          // upon server error, update error fields
          if (error.response) {
            const { data } = error.response;

            this.oldError = (data.current_password && data.current_password[0]) || "";
            this.newError = (data.new_password && data.new_password[0]) || "";
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style>
.Password__strength-meter {
  margin: 0.5rem 0 0 0 !important;
}
</style>
