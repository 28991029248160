<template>
    <div class="tw-mt-10">
        <section>
            <div
                class="tw-bg-white sm:tw-rounded-lg sm:tw-border sm:tw-border-gray-200 sm:tw-overflow-hidden"
            >
                <div class="tw-py-5 sm:tw-px-6">
                    <div
                        class="mb-6 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw--mt-2 tw--ml-4"
                    >
                        <div class="tw-mt-2 tw-ml-4">
                            <h2
                                class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                                Change your password
                            </h2>
                            <p
                                class="tw-mt-1 tw-text-sm tw-text-gray-500"
                                style="margin-bottom: 0"
                            >
                            Update your password to keep your account secure.
                            </p>
                        </div>
                    </div>
                    <ChangePasswordForm/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ChangePasswordForm from "@/components/Settings/ChangePasswordForm.vue";

export default {
    components: {
        ChangePasswordForm
    },
};
</script>

<style>
</style>
